import React, { Component } from "react"
import { Section, Title, SectionButton } from "../../utils"
import styled from "styled-components"
import { styles } from "../../utils"
import { Link } from "gatsby"

export default class cont extends Component {
  render() {
    return (
      <Section>
        <Title message="let us tell you" title="our mission" />
        <QuickInfoWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum
            unde, corporis aliquid numquam nobis, dignissimos, maxime ea tenetur
            eos possimus rerum voluptates est provident quibusdam quos beatae
            quod deserunt? Vel quam nam quaerat incidunt, ullam accusantium
            placeat dignissimos omnis est nulla quo quidem tempora fugit
            eveniet, autem commodi repellendus debitis.
          </p>
          <Link to="/about/">
            <SectionButton>About</SectionButton>
          </Link>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
const QuickInfoWrapper = styled.div`
  width: 90%;
  margin: 2rem auto;

  p {
    line-height: 2rem;
    color: ${styles.colors.mainGrey};
    word-spacing: 0.2rem;
  }

  ${SectionButton} {
    margin: 2rem auto;
  }

  @media (min-width: 768px) {
    width: 70%;
  }
  @media (min-width: 992px) {
    width: 60%;
  }
`
